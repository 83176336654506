import React, { useState } from "react";
import { Link } from "react-router-dom";
import CardComponent from "./cardComponent";
import CardComponentTwo from "./cardComponentTwo.js";

import { textColor, buttonBgColor } from "../colors/colors.js";
import { useDispatch } from "react-redux";
import { currentStep, setHouseType, setIntrestedIn } from "../redux/formSlice";

const CardContainer = ({
  data,
  setIsAnimating,
  setCurrentStep,
  currentStep,
}) => {
  const dispatch = useDispatch();
  const handleImageClick = (name,e) => {

    console.log("name1122", name);
    if (
      name === "Mehrfamilienhaus" ||
      name === "Ein- oder Zweifamilienhaus"
    ) {
      console.log("name", name);
      dispatch(setHouseType(name));
    } else if (
      name === "Wärmepumpe" ||
      name === "Sonnensystem" ||
      name === "Ganzheitliche energetische Sanierung"
    ) {
      dispatch(setIntrestedIn(name));
    }

    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      setCurrentStep((prevStep) => Math.max(0, prevStep + 1)); // Prevent going below 0
    }, 500);
  };

  return (
    <>
      {data.id !== 0 ? (
        <>
          <div
            className="text-center xs:text-xl lg:text-4xl font-bold mt-5 mb-5"
            style={{ color: textColor }}
          >
            <p>{data.heading}</p>
          </div>
         <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-5 pb-5 justify-center">
            {data.items.map((item, index) => (
              <CardComponent
                key={index}
                image={item.image}
                text={item.text}
                onClick={() => handleImageClick(item.text)}
              />
            ))}
          </div>
          {currentStep !== 0 && (
            <div className="mt-6 w-full flex justify-center items-center">
              <Link
                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white"
                style={{ background: buttonBgColor }}
              >
                Zurück
              </Link>
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className="text-center xs:text-xl lg:text-4xl font-bold mt-5 mb-5"
            style={{ color: textColor }}
          >
            <p>{data.heading}</p>
          </div>
          <div className="mt-6 w-full flex flex-col md:flex-row justify-center items-center gap-5 pb-5">
            {data.items.map((item, index) => (
              <CardComponentTwo
                key={index}
                text={item.text}
                img={item?.image}
                onClick={(e) => handleImageClick(item.text, e)}
              />
            ))}
          </div>
          {currentStep !== 0 && (
            <div className="mt-6 w-full flex justify-center items-center">
              <Link
                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white"
                style={{ background: buttonBgColor }}
              >
                Zurück
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CardContainer;
