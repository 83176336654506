import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import Footer from "../components/footer.js";

export default function Imprint() {

    useEffect(() => {
        window.scrollTo(0, 0); // Ensures the page stays at the top
      }, []);
    return (
        <>
            <Navbar />
            <section className="relative md:py-24 py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="mt-12 bg-white shadow-md rounded-lg p-8 text-left">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">Impressum</h2>

                        <div className="text-gray-700 leading-relaxed mb-4">
                            <p className="font-semibold">RAYN B GmbH</p>
                            <p>Lerchenweg 3</p>
                            <p>40789 Monheim</p>
                        </div>

                        <div className="text-gray-700 leading-relaxed mb-4">
                            <p className="font-semibold">E-Mail</p>
                            <p>
                                Sie können uns auch per E-Mail an folgende E-Mail-Adresse schreiben:
                                <a href="mailto:info@rayn-immo.de" className="text-blue-500 underline"> info@rayn-immo.de</a>
                            </p>
                            <p>(Für Angebotsanfragen oder Kooperationsanfragen, verwenden sie bitte ausschließlich den Link / unser Kontaktformular)</p>
                        </div>

                        <div className="text-gray-700 leading-relaxed mb-4">
                            <p className="font-semibold">Telefonnummer</p>
                            <p>0175/1885257</p>
                        </div>

                        <div className="text-gray-700 leading-relaxed mb-4">
                            <p className="font-semibold">Geschäftsführer</p>
                            <p>Yasir Ashraf</p>
                        </div>

                        <div className="text-gray-700 leading-relaxed mb-4">
                            <p className="font-semibold">Amtsgericht Düsseldorf</p>
                            <p>HRB 91772</p>
                            <p>Ust.-Idnr.: DE338064841</p>
                        </div>

                        <div className="text-gray-700 leading-relaxed">
                            <p className="font-semibold">Verantwortlicher i.S.d. §18 Abs. 2 MStV</p>
                            <p>Herr Shahzeb Ahmed</p>
                            <p>RAYN B GmbH</p>
                            <p>Lerchenweg 3</p>
                            <p>40789 Monheim</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
