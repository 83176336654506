import React from 'react';

const CardComponentTwo = ({ img, text, onClick }) => {
  return (
    <div
    className="max-w-sm bg-white border border-gray-200 rounded-lg hover:bg-[#098970] cursor-pointer xs:text-center transition-colors
    w-full
    duration-300 justify-center"
    onClick={onClick}
  >
    <div className="">
      <img className="rounded-t-lg pt-2 mx-auto h-32" src={img} alt={text} />
      <div className="w-full py-5">
        <p 
          className="text-xs xs:text-lg font-bold text-black"
          
        >
        {text}

    
        </p>
      </div>
    </div>
  </div>
    // <div 
    //   onClick={onClick} 
    //   className="bg-white border border-gray-200 rounded-lg shadow hover:bg-[#098970] cursor-pointer xs:text-center transition-colors duration-300 justify-center"
    // >
    //   <div className="flex flex-col items-center py-10">
    //     <img 
    //       className="w-36 h-36 md:w-72 md:h-72 mb-3 object-center" 
    //       src={img} 
    //       alt="Profile image"
    //     />
    //     <div className="text-center px-4">
    //       <p className="text-xl font-semibold text-gray-800 mb-2">{text}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CardComponentTwo;
