import React from "react";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
const ServicesCard = ({ discount, heading }) => {
  return (
    <div className="bg-white rounded-s-lg lg:gap-5 xs:gap-2 lg:mx-3 xs:px-1 my-1 ">
      <div className="lg:mx-10 xs:mx-8 text-center lg:my-5 xs:my-1">
        <div className="flex lg:gap-10 xs:gap-5  justify-center items-center">
          <p>image</p>
          <div className="my-auto">
            <p className="text-lg font-bold ">{heading}</p>
            <p className="text-lg " style={{ color: bgColor }}>
              {discount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
