// const baseColor = '#5C068C';
// const textColor = '#006837';
// const buttonBgColor = '#006837';
// const bgColor = '#8BC349';
// const progressBarColor = "#8BC349"
// const grayBackground = "#F5F5F7"

//color of 2024
// const baseColor = '#5C068C';
// const textColor = '#F6B893';
// const buttonBgColor = '#F6B893';
// const bgColor = '#F6B893';
// const progressBarColor = "#8BC349"
// const grayBackground = "#F5F5F7"

//color of 2023
// const baseColor = '#5C068C';
// const textColor = '#B62547';
// const buttonBgColor = '#B62547';
// const bgColor = '#B62547';
// const progressBarColor = "#B62547"
// const grayBackground = "#F5F5F7"

//color of the year 2022

// const baseColor = '#6160AB';
// const textColor = '#6160AB';
// const buttonBgColor = '#6160AB';
// const bgColor = '#6160AB';
// const progressBarColor = "#6160AB";
// const grayBackground = "#F5F5F7";
// const descriptionTextColor = "#FFFFFF";

//color 2013 #098970

const baseColor = '#098970';
const textColor = '#098970';
const buttonBgColor = '#098970';
const bgColor = '#098970';
const progressBarColor = "#098970";
const grayBackground = "#F5F5F7";
const descriptionTextColor = "#FFFFFF";
const animatedTextColor = "#8c8e8e"


export { baseColor, textColor, bgColor ,buttonBgColor , progressBarColor,grayBackground,descriptionTextColor,animatedTextColor};