import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { servicesData } from "../data/data";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import { fetchOurServices } from "../dbFunctions/dbFunctions.js";
import img1 from "../assets/images/Energieberatung.png"
import img2 from "../assets/images/Sanierungsfahrplan.png"
import img3 from "../assets/images/Wirtschaftlichkeitsberechnung.png"
import img4 from "../assets/images/Förderanträge.png"
import img5 from "../assets/images/HandwerkerAngebote.png"
import img6 from "../assets/images/Nachbetreuung.png"

const Services = ({ title, content,topRef }) => {
  // FETCHINF SERVICES FROM DATABASE
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getServices = async () => {
      const response = await fetchOurServices();
      console.log("🚀 ~ file: services.js:15 ~ getServices ~ response:", response)
      // console.log(response);
      setServices(response);
    };
    getServices();
  }, []);

   
  
    const scrollToTop = () => {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    
  return (
    <section
      className="relative py-8 bg-slate-100 dark:bg-slate-800"
      id="services"
    >
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3
            className="font-semibold text-2xl leading-normal mb-4 "
            style={{ color: textColor }}
          >
            {title}
          </h3>

          <p className="text-slate-400 text-xl max-w-xl mx-auto">{content}</p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6 mt-6">
          {services?.map((item, index) => {
              const images = [img1, img2, img3, img4, img5, img6];
            return (
              <div
                className="group rounded-lg shadow dark:shadow-gray-700 relative bg-white dark:bg-slate-900 p-6 overflow-hidden h-[32]"
                key={index}
              >
                <div className="flex items-center justify-center size-14 bg-gradient-to-r from-transparent to-teal-500/10 text-teal-500 text-center rounded-full group-hover:bg-teal-500/10 duration-500">
                  

                  <img src={images[index]} width={"120px"} height={"100px"} />


                </div>

                <div className="content mt-6 relative z-1">
                  <Link
                    to=""
                    className="title text-lg font-semibold"
                    style={{ color: textColor }}
                  >
                    {item?.name}
                  </Link>
                  <div
                    dangerouslySetInnerHTML={{ __html: item?.heading }}
                    className="title text-md duration-500 ease-in-out text-slate-400 line-clamp-2"
                    // style={{ color: textColor }}
                  />
                  {/* <p className="text-slate-400 mt-3">{item?.heading}</p> */}

                  <div className="mt-6"  onClick={scrollToTop}>
                    <Link
                      to={item?.url}
                      className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white"
                      style={{ background: buttonBgColor }}
                    >
                      Mehr lesen
                    </Link>
                  </div>
                  {/* <div className="mt-6">
                                          <Link to="" className=" text-[#2FD3C6]">Read More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                                      </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
