import React, { useEffect, useState } from "react";
import { Link as Link1 } from "react-scroll";
import { Link, useLocation } from "react-router-dom";

import Navbar from "../components/navbar";
import About from "../components/about";
import FormSection from "../components/formSection.jsx";
import Services from "../components/services";
import { useDispatch, useSelector } from "react-redux";
import Client from "../components/client";
import Blogs from "../components/blog";
import GetInTouch from "../components/getInTuoch";
import Footer from "../components/footer";
import { setZoomLevel } from "../redux/mediaQuerySlice";
import greenLeaf from "../assets/images/green.png"
import {
  buttonBgColor,
  descriptionTextColor,
  animatedTextColor,
} from "../colors/colors.js";
import homePic from "../assets/images/Bild.jpg";
import ScrollToTopButton from "./scroll-to-top-button.js";

export default function Index() {
  const navBarItems = useSelector((state) => state.initialData);
  const dispatch = useDispatch();
  const [data, setData] = useState(null); // Use null as the initial state to distinguish between 'no data' and 'empty data'
  const [percentage, setPercentage] = useState(20);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set a timeout to make the divs visible after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   const fetchHeading = async () => {
  //     try {
  //       const result = await getHeading();

  //       console.log("resultfromindex", result);
  //     dispatch(setInitialData(result.content[0]));
  //     } catch (error) {
  //       console.error("Failed to fetch data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchHeading();

  // }, []);

  const result = useSelector((state) => state.initialData);

  useEffect(() => {
    setData(result);
  }, [result]); // This will only run when 'result' changes

  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  const isTabView = useSelector((state) => state.mediaQuery.isTabView);

  // const [zoom, setZoom] = useState(window.devicePixelRatio * 100);

  useEffect(() => {
    const handleZoom = () => {
      const zoomLevel = Math.round(window.devicePixelRatio * 100);
      // setZoom(zoomLevel);
      dispatch(setZoomLevel(zoomLevel));
    };

    window.addEventListener("resize", handleZoom);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleZoom);
    };
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage((prev) => {
        const randomChange = Math.floor(Math.random() * 10) - 5;
        let newPercentage = prev + randomChange;
        if (newPercentage > 45) newPercentage = 40;
        if (newPercentage < 5) newPercentage = 6;
        return newPercentage;
      });
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const zoom = useSelector((state) => state.mediaQuery.zoom);
  return (
    <>
      <Navbar />
      <section
        className="relative overflow-hidden md:py-48 py-40 bg-teal-500/5 dark:bg-teal-500/20"
        id="home"
      >
        <div className="absolute inset-0 z-0 top-5 bg-no-repeat bg-black">
          <img
            className="xs:w-screen lg:w-full h-full opacity-20"
            src={homePic}
            alt="Home"
          />
          <div className="absolute inset-0 bg-gradient-to-l from-custom-green to-transparent opacity-50 z-10"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-white to-custom-green opacity-30 z-9"></div>
        </div>

        {!isMobileView && !isTabView && (
          <>
          <div
              className={`main-icon-roof rounded-xl flex justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              {/* <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 24 24"
              >
                <path
                fill="currentColor"
                d="M5.616 20q-.672 0-1.144-.472T4 18.385V5.615q0-.67.472-1.143Q4.944 4 5.616 4h12.769q.67 0 1.143.472q.472.472.472 1.144v12.769q0 .67-.472 1.143q-.472.472-1.143.472zm6.884-7.5V19h5.885q.269 0 .442-.173t.173-.442V12.5zm0-1H19V5.616q0-.27-.173-.443T18.385 5H12.5zm-1 0V5H5.616q-.27 0-.443.173T5 5.616V11.5zm0 1H5v5.885q0 .269.173.442t.443.173H11.5z"
            />
              </svg> */}
              <svg 
                className="mx-1"
                style={{
                  width: "40px",
                  height: "50px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
              <polygon points="10,40 50,10 90,40" fill="none" stroke="currentColor" strokeWidth="4" />
            </svg>
              
              {/* <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Dachdämmung <br />
                  <span className="text-[15px] text-[#F59191]">{15} - {20} %</span>
                </p>
              </div> */}
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Dachdämmung
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">{15} - {20} %</span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className={`main-icon-window rounded-xl flex justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5.616 20q-.672 0-1.144-.472T4 18.385V5.615q0-.67.472-1.143Q4.944 4 5.616 4h12.769q.67 0 1.143.472q.472.472.472 1.144v12.769q0 .67-.472 1.143q-.472.472-1.143.472zm6.884-7.5V19h5.885q.269 0 .442-.173t.173-.442V12.5zm0-1H19V5.616q0-.27-.173-.443T18.385 5H12.5zm-1 0V5H5.616q-.27 0-.443.173T5 5.616V11.5zm0 1H5v5.885q0 .269.173.442t.443.173H11.5z"
                />
              </svg>
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Fenster
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">{10} - {20} %</span>
                  </span>
                </p>
              </div>
              {/* <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Fenster <br />
                  <span className="text-[15px] text-[#F59191]">{10} - {20} %</span>
                </p>
              </div> */}
            </div>
            <div
              className={`main-icon-ventilation rounded-xl flex justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1M6 19h12V9.157l-6-5.454l-6 5.454zm2.591-5.191a3.5 3.5 0 0 1 0-1.622l-.991-.572l1-1.732l.991.573a3.5 3.5 0 0 1 1.404-.812V8.5h2v1.144c.532.159 1.01.44 1.404.812l.991-.573l1 1.731l-.991.573a3.5 3.5 0 0 1 0 1.622l.991.572l-1 1.731l-.991-.572a3.5 3.5 0 0 1-1.404.811v1.145h-2V16.35a3.5 3.5 0 0 1-1.404-.811l-.991.572l-1-1.73zm3.404.688a1.5 1.5 0 1 0 0-2.998a1.5 1.5 0 0 0 0 2.998"
                />
              </svg>
              {/* <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Lüftungssystem <br />
                  <span className="text-[15px] text-[#F59191]">5 - 10 %</span>
                </p>
              </div> */}
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Lüftungssystem
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">5 - 10 %</span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className={`main-icon-solar rounded-xl flex  justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                //width="3em"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 256 256"
              >
                <path
                  fill="currentColor"
                  d="M120 40V16a8 8 0 0 1 16 0v24a8 8 0 0 1-16 0m72 88a64 64 0 1 1-64-64a64.07 64.07 0 0 1 64 64m-16 0a48 48 0 1 0-48 48a48.05 48.05 0 0 0 48-48M58.34 69.66a8 8 0 0 0 11.32-11.32l-16-16a8 8 0 0 0-11.32 11.32Zm0 116.68l-16 16a8 8 0 0 0 11.32 11.32l16-16a8 8 0 0 0-11.32-11.32M192 72a8 8 0 0 0 5.66-2.34l16-16a8 8 0 0 0-11.32-11.32l-16 16A8 8 0 0 0 192 72m5.66 114.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32-11.32ZM48 128a8 8 0 0 0-8-8H16a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8m80 80a8 8 0 0 0-8 8v24a8 8 0 0 0 16 0v-24a8 8 0 0 0-8-8m112-88h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 0-16"
                />
              </svg>
              {/* <div className="flex flex-col mr-3   justify-center items-center text-center">
                <p
                  className=" text-[15px] "
                  style={{ color: animatedTextColor }}
                >
                  Heizung i.V.m. Photovoltaik
                  <br />
                  <span className=" text-[15px] text-[#F59191]">{10} - {35} %</span>
                </p>
              </div> */}
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Heizung i.V.m. Photovoltaik
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">{10} - {35} %</span>
                  </span>
                </p>
              </div>
              {/* <p className=" text-xl text-white">
      Solar thermal energy
      <br />
      <span className=" text-xl text-[#F59191]">10 - 30 %</span>
    </p> */}
            </div>
            <div
              className={`main-icon-exterior rounded-xl flex justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                //width="3em"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 30 30"
              >
                <path
                  fill="currentColor"
                  d="M9.91 19.56a5.1 5.1 0 0 1 2.24-4.22V5.42c0-.8.27-1.48.82-2.03s1.23-.84 2.03-.84c.81 0 1.49.28 2.04.83c.55.56.83 1.23.83 2.03v9.92c.71.49 1.25 1.11 1.64 1.84s.58 1.53.58 2.38c0 .92-.23 1.78-.68 2.56s-1.07 1.4-1.85 1.85s-1.63.68-2.56.68c-.92 0-1.77-.23-2.55-.68s-1.4-1.07-1.86-1.85s-.68-1.63-.68-2.55m1.76 0c0 .93.33 1.73.98 2.39s1.44.99 2.36.99c.93 0 1.73-.33 2.4-1s1.01-1.46 1.01-2.37c0-.62-.16-1.2-.48-1.73q-.48-.795-1.32-1.23l-.28-.14c-.1-.04-.15-.14-.15-.29V5.42c0-.32-.11-.59-.34-.81c-.23-.21-.51-.32-.85-.32c-.32 0-.6.11-.83.32s-.34.48-.34.81v10.74c0 .15-.05.25-.14.29l-.27.14q-.825.435-1.29 1.23c-.31.53-.46 1.1-.46 1.74"
                />
              </svg>
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                  Außenwanddämmung
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">15 - 25 %</span>
                  </span>
                </p>
              </div>
            </div>
            <div
              className={`main-icon-basement rounded-xl flex justify-center items-center text-center transition-all duration-1000 ${
                isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
              }`}
              style={{ backgroundColor: "rgba(0, 0, 0, 0.40)" }}
            >
              <svg
                className="mx-1"
                xmlns="http://www.w3.org/2000/svg"
                //width="3em"
                style={{
                  width: "40px",
                  height: "40px",
                  color: animatedTextColor,
                }}
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 3L2 12h3v8h14v-8h3zM9 8h4a2 2 0 0 1 2 2v1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1 1.5 1.5V16a2 2 0 0 1-2 2H9zm2 2v2h2v-2zm0 4v2h2v-2z"
                />
              </svg>
              {/* <div className="flex flex-col mr-3   justify-center items-center text-center">
                <p
                  className=" text-[15px] "
                  style={{ color: animatedTextColor }}
                >
                  Kellerdämmung
                  <br />
                  <span className=" text-[15px] text-[#F59191]">{5} %</span>
                </p>
              </div> */}
              <div className="flex flex-col mr-3 justify-center items-center text-center">
                <p className="text-[15px]" style={{ color: animatedTextColor }}>
                Kellerdämmung
                  <br />
                  <span className="flex items-center">
                    <img
                      src={greenLeaf}
                      alt="GreenLeaf"
                      style={{ height: "25px", marginRight: "2px" }} // Smaller image
                    />
                    <span className="text-[15px] text-green-500">{5} %</span>
                  </span>
                </p>
              </div>
            </div>
          </>
        )}

        <div className="z-10 container relative lg:mt-6 md:mt-6 lg:bottom-16 md:bottom-16">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 items-center">
            <div>
              {result && (
                <>
                  <h1
                    className={`font-bold lg:leading-normal leading-normal tracking-wide mb-5 text-white transition-all duration-1000 ${
                    isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
                    }`}
                    style={{ fontSize: "2.9rem" }}
                  >
                    {result?.heading}
                  </h1>
                  <p
                    className={`text-xl max-w-xl transition-all duration-1000 ${
                    isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
                    }`}
                    style={{ color: descriptionTextColor }}
                  >
                    {result?.subheading}
                  </p>
                </>
              )}

              <div className={`mt-6 transition-all duration-1000 ${
                    isVisible ? "opacity-100 scale-100" : "opacity-0 scale-75"
                    }`}>
                <Link
                  to="/form"
                  className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white cursor-pointer"
                  style={{ background: buttonBgColor }}
                >
                 {navBarItems?.navItemButtonText} <i className="mdi mdi-chevron-right ms-1"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FormSection image={data?.formImage} />
      <About
        title={data?.aboutUsTitle}
        content={data?.aboutUsContent}
        image={data?.aboutUsImage}
      />
      <Services title={data?.servicesHeading} content={data?.servicesContent} />
      <Client title={data?.reviewsTitle} content={data?.reviewsContent} />
      {/* <Blogs title={data?.blogsTitle} content={data?.blogsContent} /> */}
      <GetInTouch
        title={data?.contactUsTitle}
        content={data?.contactUsContent}
      />
      <Footer />
      <ScrollToTopButton/>
      {/* <AgencyTab /> */}
      {/* <Cta /> */}
      {/* <Pricing /> */}
      {/* <Switcher/> */}
    </>
  );
}
