import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import formReducer from "./formSlice";
import mediaQueryReducer from "./mediaQuerySlice";  // import the reducer, not the slice
import initialDataReducer from "./initialDataSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    form: formReducer,
    mediaQuery: mediaQueryReducer,  // use the same key name as the slice name
    initialData: initialDataReducer
  },
});

export default store;
