import React from "react";
import contact from '../assets/images/contact.svg'
import house from '../assets/images/form.png'
import { textColor ,buttonBgColor,bgColor } from "../colors/colors.js";
import { askQuestion } from "../dbFunctions/dbFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2'
export default function GetInTouch({title,content}){

const [formData , setFormData] = React.useState( {
    question: "",
    name: "",
    email: "",
    comment: "",
  }
);
const missingFields = [];
const handleChange = (e) => {
    const {name , value } = e.target;
  setFormData((prev) => ({
    ...prev,
    [name]:value,
  }));
};

const resetForm = () => {
    setFormData({
        question: "",
        name: "",
        email: "",
        comment: "",
      });
}
const handleSubmit = async (e) => {
  e.preventDefault();
 // Simple validation check
 if (!formData.email) {
    missingFields.push("Name");
  }
  if (!formData.name) {
    missingFields.push("E-Mail");
  }
  if (!formData.question) {
    missingFields.push("Tel-Nr");
  }
  
  if (missingFields.length > 0) {
    const missingFieldsMessage = missingFields.join(", ");
    toast.error(`Bitte füllen Sie das folgende Pflichtfeld aus: ${missingFieldsMessage}`);
    return;
  }
try {
    const response = await askQuestion(formData);
    await Swal.fire({
      title: '',
      text: 'Ihr Formular wurde übermittelt.',
      icon: 'success',
      confirmButtonText: 'Ok',
      confirmButtonColor: '#098970',
    })
    resetForm(); 
    window.scrollTo(0, 0);
  } catch (error) {
    toast.error("Beim Absenden des Formulars ist ein Fehler aufgetreten.");
    console.error("Form submission error:", error);
  }
};



    return(
        <section className="relative lg:py-24 py-16  bg-slate-100" id="contact">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="font-semibold text-2xl leading-normal mb-4 text-[#006837]">{title}</h3>

                    <p className="text-slate-400 text-xl max-w-xl mx-auto">{content}</p>
                </div>

                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
                    <div className="lg:col-span-7 md:col-span-6">
                    <img src={house} alt="" style={{ background: bgColor, height: '100%' }} className="rounded-2xl w-full object-cover"/>
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <form onSubmit={handleSubmit}> 
                                    <div className="grid lg:grid-cols-12 grid-cols-1 gap-3">
                                        <div className="lg:col-span-6">
                                            <label htmlFor="name" className="font-semibold">Name*</label>
                                            <input name="name" id="name" type="text" onChange={handleChange} value = {formData.name}  className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Name :"/>
                                        </div>
        
                                        <div className="lg:col-span-6">
                                            <label htmlFor="email" className="font-semibold">
                                            E-Mail*</label>
                                            <input name="email" id="email" type="email" onChange={handleChange} value = {formData.email} className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Email :"/>
                                        </div>

                                        <div className="lg:col-span-12">
                                            <label htmlFor="subject" className="font-semibold">
                                            Tel.-Nr. *</label>
                                            <input name="question" id="subject" onChange={handleChange} value = {formData.question} className="mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"                 placeholder="e.g. +1 234 567 890"
                                            />
                                        </div>
    
                                        <div className="lg:col-span-12">
                                            <label htmlFor="comments" className="font-semibold"> Möchten Sie uns etwas mitteilen</label>
                                            <textarea name="comment" id="comment" onChange={handleChange} value = {formData.comment} className="mt-2 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white mt-2" style={{backgroundColor:buttonBgColor}}>
                                    Nachricht senden</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    )
}