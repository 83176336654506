import { createSlice } from "@reduxjs/toolkit";

const initialDataSlice = createSlice({
  name: "initialDataSlice",
  initialState: {
    heading: "",
    subheading: "",
    aboutUsTitle: "",
    aboutUsContent: "",
    servicesHeading: "",
    servicesContent: "",
    reviewsTitle: "",
    reviewsContent: "",
    blogsTitle: "",
    blogsContent: "",
    contactUsTitle: "",
    contactUsContent: "",
    formImage: null,
    aboutUsImage: null,
  },
  reducers: {
    setInitialData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setInitialData } = initialDataSlice.actions;

export default initialDataSlice.reducer;
