import { createSlice } from "@reduxjs/toolkit";

const mediaQuerySlice = createSlice({
  name: "mediaQuery",
  initialState: {
    isMobileView: false,
    isTabView: false,
    zoom : 75
  },
  reducers: {
    setMobileView: (state, action) => {
      state.isMobileView = action.payload;
    },
    setTabView: (state, action) => {
      state.isTabView = action.payload;
    },
    setZoomLevel: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

export const { setZoomLevel,setMobileView , setTabView} = mediaQuerySlice.actions;

export default mediaQuerySlice.reducer;
