import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import secondmainlogo from "../assets/images/secondmainlogo.png";
import { Link as Link1 } from "react-scroll";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import { TiThMenuOutline } from "react-icons/ti";
import { useSelector } from "react-redux";
import {
  fetchOurServices,
  fetchNavbarPages,
} from "../dbFunctions/dbFunctions.js";

export default function Navbar() {
  const navBarItems = useSelector((state) => state.initialData);
  const location = useLocation();
  let [scroll, setScroll] = useState(false);
  let [manu, setManu] = useState(false);
  let [servicesDropDown, setServicesDropDown] = useState(false);
  let [navbarModalForMobile, setNavbarModalForMobile] = useState(false);
  // console.log(navbarModalForMobile);
  let [
    navbarModalForMobileForOurService,
    setNavbarModalForMobileForOurService,
  ] = useState(false);

  // FETCHINF SERVICES FROM DATABASE
  const [services, setServices] = useState([]);
  useEffect(() => {
    const getServices = async () => {
      const response = await fetchOurServices();
      setServices(response);
    };
    getServices();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY > 100);
      });
    };
  }, []);

  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  const isTabView = useSelector((state) => state.mediaQuery.isTabView);

  // FETCHING URLS FOR NAVBAR PAGE IF EXISTS
  const [pagesName, setPagesName] = useState([]);
  useEffect(() => {
    const getPages = async () => {
      const response = await fetchNavbarPages();
      setPagesName(response);
    };
    getPages();
  }, []);

  const zoom = useSelector((state) => state.mediaQuery.zoom);
  return (
    <nav className={`navbar ${scroll ? "" : ""}`} id="navbar">
      <div
        className="relative flex flex-wrap items-center justify-between w-screen xs:px-2 sm:px-2 md:px-4 lg:px-4 xl:px-16 2xl:px-28"
        //  className="flex "
        style={{
          height: "90px",
          marginTop: "-13px",
          background: scroll
            ? "#fff"
            : isMobileView || isTabView || location.pathname !== "/"
            ? "#192132"
            : "",
          marginRight: zoom > 100 ? "1rem" : "",
        }}
      >
        <Link className=" " to="/">
          <img
            src={secondmainlogo}
            className="h-[35px] w-[80px] ml-3"
            // style={{ width: "80px" }}
            alt=""
          />
        </Link>

        {(isMobileView || isTabView) && (
          <div className="px-3">
            <TiThMenuOutline
              className="relative h-[35px] w-[35px]"
              style={
                navbarModalForMobile
                  ? { color: textColor }
                  : { color: textColor }
              }
              onClick={() => {
                setNavbarModalForMobile(!navbarModalForMobile);
                setNavbarModalForMobileForOurService(false);
              }}
            />
          </div>
        )}

        {navbarModalForMobile && (
          <div className="absolute shadow-lg right-0 top-[5.6rem]">
            <div
              className={`px-3 py-3 text-xl rounded-l-lg rounded-b-lg`}
              style={{ background: bgColor, color: "white" }}
            >
              <li
                className="nav-item ms-0 cursor-pointer list-none hover:bg-[#72a437]"
                onClick={() => setNavbarModalForMobileForOurService(false)}
              >
                <Link
                  to="/"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  spy={true}
                  cursor="pointer"
                  className="hover:bg-[#72a437]  hover:text-white  cursor-pointer nav-link"
                >
                  Heim
                </Link>
              </li>

              <li
                className="nav-item ms-0 cursor-pointer list-none hover:bg-[#72a437]"
                onClick={() => setNavbarModalForMobileForOurService(false)}
              >
                <Link1
                  onClick={() =>
                    setNavbarModalForMobileForOurService(
                      !navbarModalForMobileForOurService
                    )
                  }
                  className=" hover:text-white cursor-pointer"
                  style={{
                    background: navbarModalForMobileForOurService
                      ? "#72a437"
                      : "",
                  }}
                >
                 
                Unsere Leistungen
                </Link1>
              </li>
              <li className="nav-item ms-0 cursor-pointer list-none hover:bg-[#72a437]">
                <Link1
                  to="about"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  spy={true}
                  className="hover:bg-[#72a437]  hover:text-white  cursor-pointer nav-link"
                >
                 Über uns
                </Link1>
              </li>
              <li
                className="nav-item ms-0 cursor-pointer list-none hover:bg-[#72a437]"
                onClick={() => setNavbarModalForMobileForOurService(false)}
              >
                <Link1
                  to="contact"
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  spy={true}
                  className="hover:bg-[#72a437]  hover:text-white  cursor-pointer nav-link"
                >
                  Kontaktieren Sie uns
                </Link1>
              </li>

              {pagesName?.map((link, index) => (
                <li
                  key={index}
                  className="nav-item ms-0 cursor-pointer list-none hover:bg-[#72a437]"
                  onClick={() => setNavbarModalForMobileForOurService(false)}
                >
                  <Link
                    className="nav-link cursor-pointer "
                    to={link.url}
                    smooth={true}
                    duration={1000}
                    activeClass="active"
                    spy={true}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </div>
          </div>
        )}

        {navbarModalForMobileForOurService && (
          <div className="absolute shadow-lg right-20 top-[12.5rem]">
            <div
              className={`px-3 py-3 text-xl rounded-l-lg rounded-b-lg`}
              style={{ background: bgColor, color: "white" }}
            >
              {services?.map((link, index) => (
                // <a key={index} href={link.url}>
                //   <p className="hover:bg-[#72a437] hover:text-white cursor-pointer">
                //     {link.name}
                //   </p>
                // </a>

                <li
                  key={index}
                  className="nav-item ms-0 cursor-pointer list-none"
                >
                  <Link
                    className="hover:bg-[#72a437] hover:text-white cursor-pointer"
                    to={link.url}
                    smooth={true}
                    duration={1000}
                    activeClass="active"
                    spy={true}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </div>
          </div>
        )}

        <div
          className={`navigation justify-between lg_992:order-1 lg_992:flex ms-auto ${
            manu ? "" : "hidden"
          }`}
          id="menu-collapse"
          // style={ {color: location.pathname !== "/" ? "white" : "",}}
        >
          <ul
            className="navbar-nav"
            id="navbar-navlist"
            style={{
              color: scroll ? textColor : "#fff",
            }}
          >
            {/* <li className="nav-item ms-0">
                            <Link1 className="nav-link" to="home" smooth={true} duration={1000} activeClass='active' spy={true}>Home</Link1>
            
            </li> */}

            <li className="nav-item ms-0">
              <Link
                className="nav-link cursor-pointer"
                to="/"
                smooth={true}
                duration={1000}
                activeClass="active"
                spy={true}
              >
                {navBarItems?.navItemOne}
              </Link>
            </li>

            <li
              className="nav-item ms-0 dropdown"
              onMouseOver={() => setServicesDropDown(true)}
              onMouseLeave={() => setServicesDropDown(false)}
            >
              <Link1
                className="nav-link cursor-pointer"
                to="services"
                smooth={true}
                duration={1000}
                activeClass="active"
                spy={true}
              >
                {navBarItems?.navItemTwo}
              </Link1>
              {servicesDropDown && (
                <ul
                  className="absolute text-lg px-2 py-2 text-white rounded-lg"
                  style={{ background: "white" }}
                >
                  {services?.map((link, index) => (
                    <a key={index} href={link.url}>
                      <li className="text-black hover:text-[#098970] cursor-pointer py-1 ">
                        {link.name}
                      </li>
                    </a>
                  ))}
                </ul>
              )}
            </li>

            <li className="nav-item ms-0">
              <Link1
                className="nav-link cursor-pointer"
                to="about"
                smooth={true}
                duration={1000}
                activeClass="active"
                spy={true}
              >
                {navBarItems?.navItemThree}
              </Link1>
            </li>
            <li className="nav-item ms-0">
              <Link1
                className="nav-link cursor-pointer"
                to="contact"
                smooth={true}
                duration={1000}
                activeClass="active"
                spy={true}
              >
                {navBarItems?.navItemFour}
              </Link1>
            </li>

            {pagesName?.map((link, index) => (
              <li key={index} className="nav-item ms-0 cursor-pointer">
                <Link
                  className="nav-link cursor-pointer"
                  to={link.url}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  spy={true}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
          <div>
            {!(isMobileView || isTabView) && (
              <div className="nav-icons flex items-center lg:order-2 ms-auto md:ms-8">
                <ul className="list-none menu-social mb-0 sm:mr-4">
                  <li className="inline">
                    <Link
                      to="/form"
                      smooth={true}
                      duration={1000}
                      activeClass="active"
                      spy={true}
                      className="h-10 px-4 text-[0.8rem] tracking-wider inline-flex items-center justify-center font-medium rounded-md text-white uppercase cursor-pointer"
                      style={{ background: buttonBgColor }}
                    >
                      {navBarItems?.navItemButtonText}
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
