import React from "react";
import { Link } from "react-router-dom";
import intrested from "../assets/images/intrested.jpg";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import CountUp from "react-countup";
import philosphy1 from '../assets/images/philosphy1.jpg';
import { useSelector } from "react-redux";

export default function About({ title, content, image }) {
  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  return (
    <section className="relative md:py-24 py-16" id="about">
      <div className="container relative">
        <div className="grid grid-cols-1 mb-8 text-center">
          <h1
            className="font-semibold text-2xl leading-normal mb-3"
            style={{ color: textColor }}
          >
            {" "}
            Warum WeLiveGreen?
          </h1>

          {/* <p className="text-slate-400 text-xl max-w-xl mx-auto">
            At our core, we are pioneers in sustainable energy solutions.
          </p> */}
        </div>
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6">
          <div className="md:col-span-6">
            <div className="lg:ms-8">
              {/* <h6
                className="text-sm font-semibold uppercase mb-2"
                style={{ color: textColor }}
              >
                Who Are We ?
              </h6> */}
              <h3
                className="font-semibold text-xl leading-normal mb-4"
                style={{ color: textColor }}
              >
                {title}
              </h3>

              <p className="text-slate-400 text-xl max-w-xl mb-6">{content}</p>
            </div>
          </div>
          
          <div className="md:col-span-6">
            <div className="lg:me-8">
              <div className="relative h-full w-full">
                <img
                  src={intrested}
                  className="w-full h-[320px] rounded-lg shadow dark:shadow-gray-700"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6 mt-5 ml-5">
          {!isMobileView && 
          <div className="md:col-span-6 pt-4 mt-4">
            <div className="lg:me-8">
              <div className="relative h-full w-full">
                <img
                style={{
                  resize:'cover'
                }}
                  src={philosphy1}
                  // {philosphy ? philosphy : solarPicForFormSec}
                  className="w-full h-[320px] rounded-lg shadow dark:shadow-gray-700"
                  alt=""
                />
              </div>
            </div>
          </div>}
          <div className="md:col-span-6">
            <div className="lg:ms-8">
              {/* <h6
                className="text-sm font-semibold uppercase mb-2"
                style={{ color: textColor }}
              >
                Who Are We ?
              </h6> */}
              <h3
                className="font-semibold text-xl leading-normal mb-4"
                style={{ color: textColor }}
              >
                Unsere Philosophie
              </h3>

              <p className="text-slate-400 text-xl max-w-xl mb-6">
              Bei WeLiveGreen steht der Kunde im Mittelpunkt. Wir glauben daran, dass eine nachhaltige Zukunft nur durch gemeinsames Handeln erreicht werden kann. Deshalb legen wir großen Wert auf Transparenz, Qualität und Kundenzufriedenheit. Jede Sanierung ist für uns ein Schritt in eine grünere Zukunft
              </p>
            </div>
          </div>
          {isMobileView && 
          <div className="md:col-span-6 pt-4 mt-4">
            <div className="lg:me-8">
              <div className="relative h-full w-full">
                <img
                style={{
                  resize:'cover'
                }}
                  src={philosphy1}
                  // {philosphy ? philosphy : solarPicForFormSec}
                  className="w-full h-[320px] rounded-lg shadow dark:shadow-gray-700"
                  alt=""
                />
              </div>
            </div>
          </div>}
        </div>
      </div>
    </section>
  );
}
