import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import bg from '../assets/images/bg/bg2.png'

import NavLight from "../components/navLight";
import About from "../components/about";
import Services from "../components/services";
import AgencyTab from "../components/agencyTab";
import Cta from "../components/cta";
import Client from "../components/client";
import Pricing from "../components/pricing";
import Blogs from "../components/blog";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import GetInTouch from "../components/getInTuoch";
import Navbar from "../components/navbar";

export default function DataPrivacy() {
    useEffect(() => {
        window.scrollTo(0, 0); // Ensures the page stays at the top
      }, []);
    return (
        <>
            <Navbar />
            <section className="relative md:py-24 py-16 bg-gray-50">
                <div className="container mx-auto px-4">
                    <div className="grid grid-cols-1 mb-8 text-center">
                        <h1 className="text-4xl font-semibold text-gray-900 mb-6">Datenschutzerklärung</h1>
                    </div>

                    <div className="bg-white shadow-md rounded-lg p-8 text-left">
                        {/* Section 1 */}
                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">1. Datenschutz auf einen Blick</h2>
                                <h3 className="font-bold text-gray-800 mb-4">
                                    Allgemeine Hinweise<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
                                Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                                passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                                persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
                                Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                            </p>
                        </div>

                        {/* Section 2 */}
                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Datenerfassung auf dieser Website</h2>
                            <h3 className="font-bold text-gray-800 mb-4">
                                Wer ist verantwortlich für die Datenerfassung auf dieser Website?<br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                                Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                                können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
                            </p>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Wie erfassen wir Ihre Daten?
                            <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
                            Daten handeln, die Sie in ein Kontaktformular eingeben.<br></br>
                            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
                            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Wofür nutzen wir Ihre Daten?
                            <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
                            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                            </p>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Welche Rechte haben Sie bezüglich Ihrer Daten?<br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
                            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
                            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
                            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
                            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.<br/>
                            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden
                            </p>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Analyse-Tools und Tools von Drittanbietern<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
                            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                            allem mit sogenannten Analyseprogrammen.<br/>
                            Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
                            Datenschutzerklärung
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">2. Hosting</h2>
                            <p className="text-gray-700 leading-relaxed">
                            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:

                            </p>
                                <h3 className="font-bold text-gray-800 mb-4">
                                Amazon Web Services (AWS)<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
                            Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg
                            (nachfolgend AWS).<br/>
                            Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS
                            verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA
                            übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt.
                            Details finden Sie hier:<br/>
                            <a href="https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/">
                            https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
                            </a>
                            Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:<br></br>
                            <a href="https://aws.amazon.com/de/privacy/?nc1=f_pr.">
                            https://aws.amazon.com/de/privacy/?nc1=f_pr.<br></br>

                            </a>
                            Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
                            Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende
                            Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
                            DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf
                            Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die
                            Einwilligung ist jederzeit widerrufbar.<br></br>
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der
                            DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung
                            europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach
                            dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere
                            Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link:
                            <a href="https://www.dataprivacyframework.gov/participant/5776">
                            https://www.dataprivacyframework.gov/participant/5776.<br></br>

                            </a>
                            
                            </p>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Auftragsverarbeitung
                            <br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
                                Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
                                geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
                                gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
                                Weisungen und unter Einhaltung der DSGVO verarbeitet
                            
                            </p>

                        </div>

                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">3. Allgemeine Hinweise und Pflichtinformationen</h2>
                                <h3 className="font-bold text-gray-800 mb-4">
                                Datenschutz<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
                            
Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
dieser Datenschutzerklärung.<br></br>
Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
und zu welchem Zweck das geschieht.<br/>
Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
möglich.<br/>
</p>
<h3 className="font-bold text-gray-800 mb-4">
Hinweis zur verantwortlichen Stelle<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">

Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/>
RAYN B GmbH<br/>
Herr Shahzeb Ahmed<br/>
Lerchenweg 3<br/>
40789 Monheim<br/>
Telefon: +49 (0) 175 188 52 57<br/>
E-Mail: info@rayn-immo.de<br/>
Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
entscheidet.<br/>
</p>
<h3 className="font-bold text-gray-800 mb-4">
Speicherdauer<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">

Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                            </p>
<h3 className="font-bold text-gray-800 mb-4">
Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
Website<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">

                            
Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
auf Grundlage von § 25 Abs. 1 TDDDG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
Absätzen dieser Datenschutzerklärung informiert.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Empfänger von personenbezogenen Daten<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">

Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei
ist teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich.
Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer
Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B. Weitergabe von Daten
an Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe
haben oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen
Vertrags über Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über
gemeinsame Verarbeitung geschlossen.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Widerruf Ihrer Einwilligung zur Datenverarbeitung
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">

Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
Datenverarbeitung bleibt vom Widerruf unberührt.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
Direktwerbung (Art. 21 DSGVO)
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">


WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
NACH ART. 21 ABS. 2 DSGVO).
</p>
<h3 className="font-bold text-gray-800 mb-4">
Beschwerderecht bei der zuständigen Aufsichtsbehörde
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">



Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Recht auf Datenübertragbarkeit
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">


Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
verlangen, erfolgt dies nur, soweit es technisch machbar ist.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Auskunft, Berichtigung und Löschung
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">


Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Recht auf Einschränkung der Verarbeitung
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">


Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
folgenden Fällen:<br/>

    <li>
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
        in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    </li>    
    <li>
        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
        statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
    </li>
    <li>
        Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
        Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
        Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
    </li>
    <li>
        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
        Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
        überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
        zu verlangen.
    </li>
Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
eines Mitgliedstaats verarbeitet werden.
</p>
<h3 className="font-bold text-gray-800 mb-4">
SSL- bzw. TLS-Verschlüsselung
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
von Dritten mitgelesen werden.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Widerspruch gegen Werbe-E-Mails
<br />
                                </h3>
                            <p className="text-gray-700 leading-relaxed">
Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von
Werbeinformationen, etwa durch Spam-E-Mails, vor
                            </p>
                        </div>

                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">4. Datenerfassung auf dieser Website</h2>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Cookies
                                <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            
Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf
Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
(Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-PartyCookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von
Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).<br/>
Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige
von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken
verwendet werden.<br/>
Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung
bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der
Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf
Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird.<br/>
Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur
technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die
Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
TDDDG); die Einwilligung ist jederzeit widerrufbar.<br/>
Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der
Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.<br/>
Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser
Datenschutzerklärung entnehmen<br/>
</p>
<h3 className="font-bold text-gray-800 mb-4">
Kontaktformular
                                <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            
Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
Einwilligung weiter.<br/>
Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
widerrufbar.<br/>
Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
insbesondere Aufbewahrungsfristen – bleiben unberührt.
</p>
<h3 className="font-bold text-gray-800 mb-4">
Anfrage per E-Mail, Telefon oder Telefax

                                <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            
Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.<br/>
Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
widerrufbar.<br/>
Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt
                            </p>
                        </div>
                        {/* Section 3 */}
                        <div className="mb-6">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">6. Newsletter</h2>
                            <h3 className="font-bold text-gray-800 mb-4">
                            Newsletterversand an Bestandskunden

                                <br />
                            </h3>
                            <p className="text-gray-700 leading-relaxed">
                            
Wenn Sie Waren oder Dienstleistungen bei uns bestellen und hierbei Ihre E-Mail-Adresse hinterlegen, kann
diese E-Mail-Adresse in der Folge durch uns für den Versand von Newslettern verwendet werden, sofern wir
Sie vorab hierüber informieren. In einem solchen Fall wird über den Newsletter nur Direktwerbung für
eigene ähnliche Waren oder Dienstleistungen versendet. Die Zusendung dieses Newsletters kann von Ihnen
jederzeit gekündigt werden. Zu diesem Zweck findet sich in jedem Newsletter ein entsprechender Link.
Rechtsgrundlage für den Versand des Newsletters ist in diesem Fall Art. 6 Abs. 1 lit. f DSGVO in Verbindung
mit § 7 Abs. 3 UWG.<br/>
Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns ggf. in einer
Blacklist gespeichert, um künftige Mailings an Sie zu verhindern. Die Daten aus der Blacklist werden nur für
diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse
als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern
(berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich
nicht befristet. Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
Interesse überwiegen.<br/>

                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}