import React, { useEffect, useState } from "react";
import solarAndHeatLogo from "../assets/images/solarwithheatpump.png";
import PersonalInfoForm from "./PersonalInfoForm/index.js";
import ProgressBar from "./progressBar.jsx";
import CardContainer from "./cardContainer.jsx";
import { useDispatch, useSelector } from "react-redux";
import heat from "../assets/images/1.png"
import solar from "../assets/images/2.png"
import light from "../assets/images/3.png"
import singleHouse from '../assets/images/singlehouse.png'
import multiHouse from '../assets/images/doublehouse.png'

import { setStep } from "../redux/formSlice.js";

const FormikForm = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [previousSelectedCategory, setPreviousSelectedCategory] =
    useState(null);

  const handleImageClick = (category) => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
      setCurrentStep((prevStep) => prevStep + 1);
    }, 500);
  };
  useEffect(() => {
    dispatch(setStep(currentStep));
  }, [currentStep]);

  const data = [
    {
      id: 0,
      heading: "Was möchten Sie energetisch optimieren?*",
      items: [
        {
          // image: solarAndHeatLogo,
          text: "Ein- oder Zweifamilienhaus",
          image:singleHouse
        },
        {
          // image: solarAndHeatLogo,
          text: "Mehrfamilienhaus",
          image:multiHouse
        },
      ],
    },
    {
      id: 1,
      heading: "Was interessiert Sie?",
      items: [
        {
          image: heat,
          // text: "Heat Pump",
          text: "Wärmepumpe",
        },
        {
          image: solar,
          // text: "Solar System",
          text: "Photovoltaikanlage",
        },
        // {
        //   image: light,
        //   text: "Holistic Energy-Efficient Renovation",
        // },
        {
          image: light,
          text: "Ganzheitliche energetische Sanierung",
        },
      ],
    },
  ];

  return (
    <div className="flex flex-col items-center z-10">
      {/* <ProgressBar className="" currentStep={currentStep} /> */}

      <div
        className={`transition-all duration-1000 ${
          isAnimating ? "animate-slideInRight" : ""
        }`}
        // style={{ width: "70%" }}
      >
        {currentStep === 0 ? (
          <>
            <CardContainer
              data={data[0]}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              isAnimating={isAnimating}
              setIsAnimating={setIsAnimating}
              setPreviousSelectedCategory={setPreviousSelectedCategory} // Pass this prop
            />
          </>
        ) : currentStep === 1 ? (
          <>
            <CardContainer
              data={data[1]}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              isAnimating={isAnimating}
              setIsAnimating={setIsAnimating}
            />
          </>
        ) : (
          <PersonalInfoForm
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
        )}
      </div>
    </div>
  );
};
export default FormikForm;
