// import axios from "axios";

// const IPBASE_API_KEY = "";

// const fetchBlogs = async (latitude, longitude) => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/blog/getAllBlog`
//       // "http://localhost:8080/api/admin/blog/getAllBlog"
//     );
//     console.log("Response data:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// const fetchOurServices = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/service/getAllServices`
//       // "http://localhost:8080/api/admin/service/getAllServices"
//     );
//     console.log("Response data:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// const fetchNavbarPages = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/page/getAllPages`
//       // "http://localhost:8080/api/admin/page/getAllPages"
//     );
//     // const response = await axios.get(
//     //   "http://localhost:8080/api/admin/navbar/getAllNavbars"
//     // );
//     console.log("Response data:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };
// const fetchNavbarUrls = async () => {
//   try {
//     const response = await axios.get(
//       // "http://localhost:8080/api/admin/navbar/getAllNavbars"
//       `${process.env.REACT_APP_BASE_URL}/navbar/getAllNavbars`
//     );
//     console.log("Response data:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };
// const fetchAllReviews = async () => {
//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASE_URL}/review/getAllReviews`
//       // "http://localhost:8080/api/admin/review/getAllReviews"
//     );
//     console.log("Response data:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// export {
//   fetchBlogs,
//   fetchOurServices,
//   fetchNavbarPages,
//   fetchNavbarUrls,
//   fetchAllReviews,
// };

const fetchBlogs = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/blog/getAllBlog`
    );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchOurServices = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/service/getAllServices`
    );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchNavbarPages = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/page/getAllPages`
    );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchNavbarUrls = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/navbar/getAllNavbars`
    );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const fetchAllReviews = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/review/getAllReviews`
    );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};
const getHeading = async () => {
  try {
 
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/updateContent/getData`
    );
    // const response = await fetch(
    //   `${process.env.REACT_APP_BASE_URL}/homePageText/getHomepageText`
    // );
    // const response = await fetch( "http://localhost:8080/api/admin/homePageText/getHomepageText" );
    const data = await response.json();
    // console.log("Response data:", data);
    return data;
  } catch (error) {
    console.error("Error:", error);
  }
};

const postInterestedForm = async (formData) => {
  try {
    // const url = "http://localhost:8080/api/admin/contactUs/createOrder"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/contactUs/createOrder`,
      {
        // const response = await fetch(url, {
        method: "POST", // Specify the method
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify(formData), // Convert the form data to a JSON string
      }
    );

    if (!response.ok) {
      // Handle errors if response is not OK
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // console.log("Response data:", data);
    return data.message;
  } catch (error) {
    console.error("Error:", error);
  }
};

const askQuestion = async (formData) => {
  try {
    const url ="http://localhost:8080/api/admin/ask/questions"
    const response = await fetch(
      `${url}`,
      {
        // const response = await fetch(url, {
        method: "POST", // Specify the method
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify(formData), // Convert the form data to a JSON string
      }
    );

    if (!response.ok) {
      // Handle errors if response is not OK
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    // console.log("Response data:", data);
    return data.message;
  } catch (error) {
    console.error("Error:", error);
  }
};

export {
  getHeading,
  askQuestion,
  fetchBlogs,
  fetchOurServices,
  fetchNavbarPages,
  fetchNavbarUrls,
  fetchAllReviews,
  postInterestedForm,
};
