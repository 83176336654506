import React from "react";
const CardComponent = ({ image, text, onClick }) => {
  const modifiedText = text.length > 15
    ? `${text.slice(0, 15)}\n${text.slice(15)}`
    : text;
  return (
    <div
      className="max-w-sm bg-white border border-gray-200 rounded-lg hover:bg-[#098970] cursor-pointer xs:text-center transition-colors duration-300 justify-center"
      onClick={onClick}
    >
      <div className="">
        <img className="rounded-t-lg mx-auto h-32" src={image} alt={text} />
        <div className="w-full py-5">
          <p 
            className="text-xs xs:text-lg font-bold text-black"
            style={{
            }}
          >
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
