import React, { useState ,useEffect ,useRef} from "react";
import { FaCheckCircle } from "react-icons/fa";
import Blogs from "../components/blog.js";
import Footer from "../components/footer.js";
import Navbar from "../components/navbar.js";
import OurOfferService from "../components/ourOfferService.jsx";
import HowItWorks from "../components/howItWorks.jsx";
import FormSection from "../components/formSection.jsx";
import Client from "../components/client.js";
import Services from "../components/services";
import star from "../assets/images/star.png";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import GetInTouch from "../components/getInTuoch";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const OurServiceComponent = ({ description, offers, heading, image,name }) => {
  const topRef = useRef(null);
  const [lastPart, setLastPart] = useState('');
  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const url = window.location.href;
    const urlParts = url.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    setLastPart(lastPart);
  }, []);
  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  const isTabView = useSelector((state) => state.mediaQuery.isTabView);
const result = useSelector((state) => state.initialData);
  return (
    <>
      <Navbar />

      <section ref={topRef} className="relative table w-full h-full xs:py-[1rem] sm:py-[2rem] md:py-[4rem] mt-8 overflow-hidden">
        <div className="h-full w-full grid mt-8 px-[14px] md:grid-cols-1 lg:grid-cols-2 lg:px-28 gap-[2rem] md:gap-[5rem]">
          <div className="pb-5">
            <img
              src={image ? image : ""}
              className="inline-block dark:hidden rounded-xl shadow-md object-cover w-full h-[500px]"
              alt=""
              style={isMobileView || isTabView ? { marginTop: "50px" } : {}}
            />
          </div>
          <div>
            <div   className="font-bold lg:leading-normal leading-normal tracking-wide mobile:text-lg lg:text-4xl"
              style={{ color: textColor }}>
               {name}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: heading }}
              className="font-bold text-slate-400 lg:leading-normal leading-normal tracking-wide mobile:text-lg lg:text-2xl mt-3"
              style={{ color: textColor }}
            />

                    <div className="nav-icons flex items-center lg:mt-10 sm:mt-6 md:mt-8 mt-4">
                    <Link
                      to="/form"
                      smooth={true}
                      duration={1000}
                      activeClass="active"
                      spy={true}
                      className="h-10 px-4 text-[0.8rem] tracking-wider inline-flex items-center justify-center font-medium rounded-md text-white uppercase cursor-pointer"
                      style={{ background: buttonBgColor }}
                    >
                      Kostenlos anfragen
                    </Link>
           
              </div>
            {offers?.map(
              (offer, index) =>
                offer && (
                  <div
                    key={index}
                    className="w-full py-1 flex gap-3 justify-start items-center"
                  >
                    <FaCheckCircle
                      className="mobile:text-3xl"
                      style={{ color: bgColor }}
                    />
                    <p className="text-slate-400 mobile:text-sm lg:text-lg">
                      {offer}
                    </p>
                  </div>
                )
            )}

          
          </div>
        </div>
        <div
        
              dangerouslySetInnerHTML={{ __html: description }}
              className="mobile:text-xs lg:text-xl  mobile:py-1 lg:my-5 mobile:my-1 text-slate-400 w-10/12 text-justify m-auto"
              style={{ color: textColor }}
            />
      </section>

      {/* <HowItWorks /> */}
      <Services topRef={topRef} title={result?.servicesHeading} content={result?.servicesContent} />
      {/* <FormSection /> */}
      {/* <OurOfferService /> */}
      <Client  title={result?.reviewsTitle} content={result?.reviewsContent}/>
      {/* <Blogs title={result?.blogsTitle} content={result?.blogsContent} /> */}
      <GetInTouch  title={result?.contactUsTitle} content={result?.contactUsContent}/>
      <Footer />
    </>
  );
};

export default OurServiceComponent;
