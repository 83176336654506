import React, { useEffect, useState } from "react";
import { Link as Link1 } from "react-scroll";
import Navbar from "../components/navbar";
import About from "../components/about";
import FormSection from "../components/formSection.jsx";
import Services from "../components/services";

import Cta from "../components/cta";
import Client from "../components/client";
import Pricing from "../components/pricing";
import Blogs from "../components/blog";
import GetInTouch from "../components/getInTuoch";
import Footer from "../components/footer";

import { getHeading } from "../dbFunctions/dbFunctions.js";
import {
  textColor,
  buttonBgColor,
  descriptionTextColor,
} from "../colors/colors.js";
import homePic from "../assets/images/Bild.jpg";

export default function Index() {
  const [data, setData] = useState(null); // Use null as the initial state to distinguish between 'no data' and 'empty data'
  const [loading, setLoading] = useState(false); // Loading state
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Set a timeout to make the divs visible after 2 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchHeading = async () => {
      try {
        const result = await getHeading();
        setData(result);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHeading();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <Navbar /> */}

      <FormSection />
      {/* <About /> */}

      {/* <Blogs /> */}
      {/* <GetInTouch /> */}
      {/* <Footer /> */}
      {/* <Switcher/> */}
    </>
  );
}
