import "./App.css";
import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Index from "./pages";
import { useDispatch, useSelector } from "react-redux";
import { setInitialData } from "./redux/initialDataSlice.js";
import { getHeading } from "./dbFunctions/dbFunctions.js";
import {
  setMobileView,
  setTabView,
  setZoomLevel,
} from "./redux/mediaQuerySlice";
import PagesComponent from "./components/pagesComponent";
import {
  fetchNavbarPages,
  fetchOurServices,
  fetchBlogs,
} from "./dbFunctions/dbFunctions";

import OurServiceComponent from "./components/ourServiceComponent.jsx";

import IndexTwo from "./pages/index-two.js";
import Imprint from "./pages/imprint.js";
import DataPrivacy from "./pages/data-privacy.js";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkMobileView = () => {
      if (window.innerWidth <= 640) {
        dispatch(setMobileView(true));
      } else {
        dispatch(setMobileView(false));
      }
    };
    const checkTabView = () => {
      if (window.innerWidth > 640 && window.innerWidth <= 992) {
        dispatch(setTabView(true));
      } else {
        dispatch(setTabView(false));
      }
    };
    checkTabView();
    checkMobileView(); // Check on initial load
    window.addEventListener("resize", checkTabView);
    window.addEventListener("resize", checkMobileView); // Check on window resize

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkMobileView);
      window.removeEventListener("resize", checkTabView);
    };
  }, [dispatch]);

  useEffect(() => {
    const loadCookieConsentScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "//www.freeprivacypolicy.com/public/cookie-consent/4.1.0/cookie-consent.js";
      script.charset = "UTF-8";
  
      // Wait for the script to load before running the cookieconsent code
      script.onload = () => {
        if (window.cookieconsent) {
          window.cookieconsent.run({
            notice_banner_type: "interstitial",
            consent_type: "express",
            palette: "light",
            language: "de",
            page_load_consent_levels: ["strictly-necessary"],
            notice_banner_reject_button_hide: false,
            preferences_center_close_button_hide: false,
            page_refresh_confirmation_buttons: false,
            website_name: "We Live Green",
            website_privacy_policy_url: "https://www.welivegreen.de/Data-Privacy",
          });
  
          // Add custom styles to the consent modal
          const style = document.createElement('style');
          style.innerHTML = `
            #cookieconsent {
              transform: translate(-50%, -50%);
              width: 80%;
              max-width: 400px;
              border-radius: 15px;
              box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
            }
            #cookieconsent .cc-window {
              border-radius: 15px !important;
            }
          `;
          document.head.appendChild(style);
        } else {
          console.error("cookieconsent script failed to load");
        }
      };
  
      // Append the script to the body
      document.body.appendChild(script);
    };
  
    loadCookieConsentScript();
  }, []);
  

  // FETCHING URLS FOR NAVBAR PAGE IF EXISTS
  const [urls, setUrls] = useState([]);
  const [servicesUrl, setServicesUrl] = useState([]);
  const [blogsUrl, setBlogsUrl] = useState([]);
  useEffect(() => {
    const getUrls = async () => {
      const response = await fetchNavbarPages();
      // console.log("urls" , response);
      setUrls(response);
    };
    const geServicesUrls = async () => {
      const response = await fetchOurServices();
      // console.log("ServicesFromDb" , response);
      setServicesUrl(response);
    };
    const getBlogsUrls = async () => {
      const response = await fetchBlogs();
      // console.log("blogsFromDb" , response);
      setBlogsUrl(response);
    };
    getBlogsUrls();
    geServicesUrls();
    getUrls();
  }, []);

  //   useEffect(() => {
  //   const API_URL = process.env.REACT_APP_BASE_URL;
  //   console.log(API_URL);
  // }, []);

  useEffect(() => {
    const fetchHeading = async () => {
      try {
        const result = await getHeading();
        // Ensure that `result` and `result.content` are valid and not undefined or null
        if (result && result.content && result.content[0]) {
          dispatch(setInitialData(result.content[0]));
        } else {
          console.error("Unexpected result structure:", result);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchHeading();
  }, []);

  const result = useSelector((state) => state.initialData);



  return (
    <>
    <noscript>
        Cookie Consent by{" "}
        <a href="https://www.freeprivacypolicy.com/">Free Privacy Policy Generator</a>
      </noscript>

      {/* Below is the link that users can use to open Preferences Center to change their preferences */}
    <Routes>
      <Route path="/" element={<Index />} />
      {/* <Route path="/energy-consulting" element={<EnergyConsulting />} />
      <Route path="/craftsman-offers" element={<CraftsManOffers />} />
      <Route path="/funding-service" element={<FundingService />} /> */}
      {/* <Route path="/index-three" element={<IndexThree />} />
      <Route path="/index-four" element={<IndexFour />} />
      <Route path="/index-five" element={<IndexFive />} />
      <Route path="/index-six" element={<IndexSix />} />
      <Route path="/index-seven" element={<IndexSeven />} />
      <Route path="/login" element={<LoginPage />} /> */}
      <Route path="/form" element={<IndexTwo />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/Data-privacy" element={<DataPrivacy />} />

      {urls?.map((url) => (
        <Route
          key={url.url}
          path={url.url}
          element={
            <PagesComponent
              image={url.image ? url.image : url.coverImage}
              content={url.content}
              title={url.title}
              name={url.name}
              createdAt={url.createdAt}
              seorobotAccess={url.seorobotAccess}
              status={url.status}
              metaDescription={url.metaDescription}
            />
          }
        />
      ))}
      {servicesUrl?.map((url) => (
        <Route
          key={url.url}
          path={url.url}
          element={
            <OurServiceComponent
              image={url.image ? url.image : url.coverImage}
              heading={url.heading}
              url={url.url}
              icon={url.icon}
              offers={url.offers}
              description={url.description}
              name={url.name}
            />
          }
        />
      ))}
      {blogsUrl?.map((url) => (
        <Route
          key={url.url}
          path={url.url}
          element={
            <PagesComponent
              image={url.image ? url.image : url.coverImage}
              content={url.content}
              title={url.title}
              name={url.name}
              createdAt={url.createdAt}
              seorobotAccess={url.seorobotAccess}
              status={url.status}
              description={url.description}
            />
          }
        />
      ))}
    </Routes>
    </>
  );
}

export default App;
