import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "formSlice",
  initialState: {
    currentStep: 0,
    houseType: "",
    interestedIn: "",
  },
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload
    },
    setHouseType: (state, action) => {
      state.houseType = action.payload;
    },
    setIntrestedIn: (state, action) => {
      state.interestedIn = action.payload;
    },
  },
});

export const { setStep , setHouseType , setIntrestedIn} = formSlice.actions;

export default formSlice.reducer;
