import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { blogData } from "../data/data";
import { textColor, buttonBgColor, bgColor } from "../colors/colors.js";
import { FiCalendar, FiClock } from "../assets/icons/vander";
import { fetchBlogs } from "../dbFunctions/dbFunctions.js";
import imageOne from "../assets/images/blog/01.jpg";
import imageTwo from "../assets/images/blog/02.jpg";
import imageThree from "../assets/images/blog/03.jpg";
import axios from "axios";

export default function Blogs({title,content}) {
  const [blogs, setBlogs] = useState([]);

  //FETCHING BLOGS DATA FROM BACKEND
  useEffect(() => {
    const getBlogs = async () => {
      const response = await fetchBlogs();
      // console.log(response);
      setBlogs(response);
    };
    getBlogs();
  }, []);

  return (
    <section className="relative py-10 bg-slate-100" id="blog">
      <div className="container relative">
        <div className="grid grid-cols-1 pb-6 text-center">
          <h3
            className="font-semibold text-2xl leading-normal mb-4 "
            style={{ color: textColor }}
          >
          {title}
          </h3>

          <p className="text-slate-400 text-xl max-w-xl mx-auto">
          {content}
          </p>
        </div>

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
          {blogs?.map((item, index) => {
            return (
              <div className="group relative overflow-hidden" key={index}>
                <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                  <img
                    src={item.coverImage ? item.coverImage : imageOne}
                    className="group-hover:scale-110 w-full h-[300px] duration-500"
                    alt=""
                  />
                </div>

                <div className="mt-6">
                  <div className="flex mb-4">
                    <span className="flex items-center text-slate-400 text-sm">
                      <FiCalendar className="size-4 text-slate-900 dark:text-white me-1.5" />
                      {item.createdAt}
                    </span>
                    <span className="flex items-center text-slate-400 text-sm ms-3">
                      <FiClock className="size-4 text-slate-900 dark:text-white me-1.5" />
                      5 min read
                    </span>
                  </div>

                  {/* <Link
                    to=""
                    className="title text-lg font-semibold duration-500 ease-in-out"
                    style={{ color: textColor }}
                  >
                    {item.title}
                  </Link> */}

                  <div
                    dangerouslySetInnerHTML={{ __html: item?.title }}
                    className="title text-lg font-semibold duration-500 ease-in-out"
                    style={{ color: textColor }}
                  />
                 

                  <div
                    dangerouslySetInnerHTML={{ __html: item?.description }}
                    className="text-slate-400 mt-2 line-clamp-3"
                  />

                  <div className="mt-6">
                    <Link
                      to={item.url}
                      className="h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md  text-white"
                      style={{ background: buttonBgColor }}
                    >
                      Mehr lesen
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
