import React from "react";
import { Link } from "react-router-dom";
import mainLogo from "../assets/images/mainLogo.png";
import secondmainlogo from "../assets/images/secondmainlogo.png";
import { useSelector } from "react-redux";
import {
  FiShoppingCart,
  FiDribbble,
  FiLinkedin,
  FiFacebook,
  FiInstagram,
  FiTwitter,
  FiMail,
} from "../assets/icons/vander";

export default function Footer() {

  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  const isTabView = useSelector((state) => state.mediaQuery.isTabView);
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="px-0 border-t border-slate-800">
        <div className="container relative text-center"
          style={{ height: isMobileView || isTabView ? "120px" : "" }}
        >
          <div className="grid lg:grid-cols-12 grid-cols-3 items-center h-20 "

          >
            <div className="lg:col-span-5 md:text-start text-center">
              <div className="flex flex-col lg:flex-row">
                <Link className="navbar-brand md:me-8" to="/">
                  <img
                    src={secondmainlogo}
                    className="inline-block dark:hidden h-[35px] w-[80px] ml-3"
                    alt=""
                  />
                </Link>
                <div className="flex flex-col lg:flex-row lg:ml-4">
                  <li className="inline">
                    <Link
                      to="/imprint"
                      className="text-sm hover:text-teal-500 duration-500 ease-in-out"
                    >
                      Impressum
                    </Link>
                  </li>
                  <li className="inline lg:ml-4">
                    <Link
                      to="/Data-Privacy"
                      className="text-sm hover:text-teal-500 duration-500 ease-in-out whitespace-nowrap"
                    >
                     Datenschutzerklärung
                    </Link>
                  </li>
                  <li className="inline lg:ml-4">
                    <Link
                      to="#"
                      className="text-sm hover:text-teal-500 duration-500 ease-in-out whitespace-nowrap"
                    >
                      Cookie Einstellungen
                    </Link>
                  </li>
                </div>
              </div>
            </div>




            <div className="lg:col-span-4 text-center mt-6 md:mt-0">
              <p className="mb-0">
                © {new Date().getFullYear()} 
                {/* <i className="mdi mdi-heart text-red-600"></i> by{" "} */}
                
                &nbsp;WeLiveGreen
                  
             
                All rights reserved.
              </p>
            </div>

            <ul className="lg:col-span-3 list-none md:text-end text-center mt-6 md:mt-0">
              {/* <li className="inline">
                <Link
                  to="https://1.envato.market/upcover-react"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiShoppingCart
                    data-feather="shopping-cart"
                    className="size-4 align-middle"
                    title="Buy Now"
                  />
                </Link>
              </li> */}
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiDribbble
                    className="size-4 align-middle"
                    title="dribbble"
                  />
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiLinkedin
                    className="size-4 align-middle"
                    title="Linkedin"
                  />
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiFacebook
                    className="size-4 align-middle"
                    title="instagram"
                  />
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiInstagram
                    className="size-4 align-middle"
                    title="instagram"
                  />
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  target="_blank"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiTwitter className="size-4 align-middle" title="twitter" />
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://softsuitetech.com/"
                  className="size-8 inline-flex justify-center items-center border border-gray-800 rounded-md hover:border-teal-500 dark:hover:border-teal-500 hover:bg-[#8BC349] dark:hover:bg-teal-500"
                >
                  <FiMail className="size-4 align-middle" title="email" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
