import React, { useMemo } from "react";
import aboutImage from "../assets/images/specilizes.jpg";
import { textColor } from "../colors/colors.js";
import FormikForm from "./formikForm.jsx";
import { Link, useLocation } from "react-router-dom";
import ProgressBar from "./progressBar.jsx";
import { useSelector } from "react-redux";

export default function FormSection({ image }) {
  const location = useLocation();
  const currentStep = useSelector((state) => state.form.currentStep);
  // Memoize the image section to avoid re-renders
  const ImageSection = useMemo(
    () => (
      <div className="relative flex justify-center items-center w-full h-full object-cover">
        <img
          src={image ? image : aboutImage}
          className="rounded-lg shadow dark:shadow-gray-700 w-full h-full object-cover"
          alt="About"
        />
      </div>
    ),
    [image] // Dependency array
  );

  return (
    <section
      className="relative md:py-[3rem] py-8 bg-slate-100"
      style={{
        marginTop: location.pathname === "/form" ? "0px" : "10px",
      }}
    >
      {location.pathname === `/form` && (
        <div className="flex justify-end items-end mr-20 cursor-pointer">
          <Link to={"/"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="3em"
              height="3em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
              />
            </svg>
          </Link>
        </div>
      )}

      <div className="grid grid-cols-1 pb-6 text-center">
        <h1
          className="font-semibold text-2xl leading-normal mb-3"
          style={{ color: textColor }}
        >
          Sichern Sie jetzt Ihre Vorteile
        </h1>

        <p className="text-slate-400 text-xl max-w-xl mx-auto">
        Sind Sie bereit, den nächsten Schritt zu gehen und Ihre Immobilie energetisch zu sanieren? Kontaktieren Sie uns noch heute für ein unverbindliches Beratungsgespräch. Gemeinsam machen wir Ihre Immobilie fit für die Zukunft!
        </p>
      </div>

      <div className="container relative">
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-10"
          style={{
            marginTop: location.pathname === `/form` ? "40px" : "",
          }}
        >
          <div>{ImageSection}</div>
          <div className="justify-center items-center">
            <div className="flex flex-col justify-center items-top items-center">
              <ProgressBar currentStep={currentStep} />
            </div>
            <div className="flex justify-center items-center text-center w-full h-full">
              <FormikForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
