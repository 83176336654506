import React from "react";
import { textColor ,buttonBgColor,bgColor ,} from "../colors/colors.js";
const ProgressBar = ({currentStep}) => {
  console.log(currentStep)
    let totalSteps = 2;
    const progressPercentage = (currentStep / (totalSteps)) * 100;
  return (
    <div className="w-52  bg-gray-200 rounded-full h-2">
      <div
        className=" h-2.5 rounded-full" 
        style={{ width: `${progressPercentage}%` , background : textColor}}
      ></div>
    </div>
  );
};

export default ProgressBar;
