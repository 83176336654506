import React, { useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Blogs from "./blog.js";
import { useSelector } from "react-redux";

const PagesComponent = ({ title, content, image, description }) => {
  const isMobileView = useSelector((state) => state.mediaQuery.isMobileView);
  const isTabView = useSelector((state) => state.mediaQuery.isTabView);

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <section className="relative table w-full h-full xs:py-[1rem] sm:py-[2rem] md:py-[4rem] overflow-hidden">
        <div className="h-full w-full grid mt-8 px-[14px] lg:px-28 gap-[2rem] md:gap-[5rem] relative">
          <div className="absolute flex justify-center items-center sm:px-[14px] xs:px-[14px] md:px-28 top-[40%] z-20">
            <div
              style={
                isMobileView || isTabView
                  ? { marginLeft: "14px", marginRight: "14px" }
                  : {}
              }
              className="bg-opacity-40 bg-black text-white xs:text-3xl sm:text-3xl md:text-4xl font-bold p-4 rounded-lg"
            >
              <p>{title}</p>
            </div>
          </div>
          <div className="pb-5 z-10">
            <img
              src={image ? image : ""}
              className="relative inline-block dark:hidden rounded-xl shadow-md max-h-[700px] object-cover w-full"
              alt=""
              style={isMobileView || isTabView ? { marginTop: "50px" } : {}}
            />
          </div>
        </div>

        {/* CONTENT SECTION */}

        <div className="table w-full h-full overflow-hidden">
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="text-slate-400 text-xl h-full w-full grid mt-8 px-[14px] lg:px-28 gap-[2rem] md:gap-[5rem]"
          />
        </div>
        {description && (
          <div className="table w-full h-full overflow-hidden">
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="text-slate-400 text-xl h-full w-full grid mt-8 px-[14px] lg:px-28 gap-[2rem] md:gap-[5rem]"
            />
          </div>
        )}
      </section>

      {description ? "" : <Blogs />}
      <Footer />
    </>
  );
};

export default PagesComponent;
