import React,{useEffect} from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { textColor, buttonBgColor } from "../../colors/colors.js";
import { postInterestedForm } from "../../dbFunctions/dbFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2'

const PersonalInfoForm = ({ nextStep, setCurrentStep, currentStep }) => {
  const navigate = useNavigate();
  const houseType = useSelector((state) => state.form.houseType);
  const interestedIn = useSelector((state) => state.form.interestedIn);

  console.log("intrestedIn",interestedIn)

  const initialValues = {
    houseType: houseType || "",
    interestedIn: interestedIn || "",
    name: "",
    email: "",
    phoneNumber: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    // Simple validation check
    if (!values.name || !values.email || !values.phoneNumber) {
      toast.error("Form must have valid values in all required fields.");
      return;
    }
  
    try {
      const response = await postInterestedForm(values);
  
     await Swal.fire({
        title: '',
        text: 'Ihre Anfrage wurde eingereicht. Wir werden uns zeitnah bei Ihnen melden.',
        icon: 'success',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#098970',
      })

          resetForm();
          setCurrentStep(0);
          window.scrollTo(0, 0);
          navigate("/");

    } catch (error) {
      toast.error("Beim Absenden des Formulars ist ein Fehler aufgetreten.");
      console.error("Form submission error:", error);
    }
  };
  
  
  return (
    <div className="w-full h-full pt-[5%]">
      <div className="flex flex-col items-center">
        <h1
          className="xs:text-2xl lg:text-4xl font-bold mb-4"
          style={{ color: textColor }}
        >
           Ihre Kontaktdaten
        </h1>
        <p className="text-gray-600 mb-6">
        Bitte geben Sie Ihren Namen, Ihre E-Mail-Adresse und Ihre Telefonnummer an.
        </p>
      </div>
      <ToastContainer />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {() => (
          <Form className="flex flex-col items-center">
            <div className=" mb-4 w-full sm:w-2/3">
              <label
                htmlFor="name"
                className="block text-md font-medium text-gray-700 text-left"
              >
                Name*
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="mt-1 p-3 border rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                placeholder="e.g. Stephen King"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="mb-4 w-full sm:w-2/3">
              <label
                htmlFor="email"
                className="block text-md font-medium text-gray-700 text-left"
              >
               E-Mail-Adresse*
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                className="mt-1 p-3 border rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                placeholder="e.g. stephenking@gmail.com"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            <div className="mb-4 w-full sm:w-2/3">
              <label
                htmlFor="phoneNumber"
                className="block text-md font-medium text-gray-700 text-left"
              >
               
            Telefonnummer*
              </label>
              <Field
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                className="mt-1 p-3 border rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                placeholder="e.g. +1 234 567 890"
              />
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>
            <div className="flex w-full justify-between items-center">
              <div className="mt-6 items-center">
                <Link
                  onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
                  className="text-md h-10 px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white"
                  style={{ background: buttonBgColor }}
                >
                  
Zurück
                </Link>
              </div>
              <div className="mt-6 items-center">
                <button
                  type="submit"
                  className="h-10 text-md px-6 tracking-wide inline-flex items-center justify-center font-medium rounded-md text-white"
                  style={{ backgroundColor: buttonBgColor }}
                >
                  
Einreichen
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfoForm;
